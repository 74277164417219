* {
  box-sizing: border-box;
  /* border: 1px dashed rgba(0, 0, 255, 0.2); */
  user-select: none;
}

.all-borders * {
  border: 1px dashed rgba(0, 0, 255, 0.2);
}

html,
body,
#root {
  min-height: 100vh;
}

#root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media (min-width: 425px) {
  #root {
    align-items: center;
    justify-content: flex-start;
  }
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "NittiGrotesk-Normal", Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 1000ms ease;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: bottom;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000000;
}
